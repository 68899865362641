<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <base-material-card
      icon="mdi-clipboard-text"
      title="Liste des Jantes"
      class="px-5 py-3"
    >
      <v-row style="margin: 20px 0">
        <v-col
          cols="12"
          md="2"
        >
          <v-btn
            color="info"
            @click="synchronisationAlgolia()"
          >
            <v-icon
              class="mr-2"
              background-color="success"
            >
              mdi-sync
            </v-icon>
            Synchroniser
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          <v-btn
            color="info"
            @click="ajouterJante()"
          >
            <v-icon
              class="mr-2"
              background-color="success"
            >
              mdi-plus-circle
            </v-icon>
            Ajouter une jante
          </v-btn>
        </v-col>
      </v-row>
      <v-row
        style="margin: 20px 0"
      >
        <v-col
          cols="12"
          md="2"
        >
          <v-btn
            color="success"
            @click="trierMarque()"
          >
            <v-icon
              class="mr-2"
              background-color="success"
            >
              mdi-brightness-auto
            </v-icon>
            Trier par marque
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          <v-btn
            color="success"
            @click="trierMarque()"
          >
            <v-icon
              background-color="success"
              class="mr-2"
            >
              mdi-car
            </v-icon>
            Trier par modèle
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          md="2"
          class="text-right"
        >
          <div
            v-if="recherche===true"
            class="my-2"
          >
            <v-btn
              icon
              small
              @click="supprimerNom()"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="rechercheNom"
            label="Rechercher"
            color="success"
            hide-details
            style="padding-top: 0px;"
          >
            <template
              v-if="$vuetify.breakpoint.mdAndUp"
              v-slot:append-outer
            >
              <v-btn
                class="mt-n2"
                small
                @click="rechercherNom(rechercheNom)"
              >
                <v-icon
                  background-color="success"
                >
                  mdi-magnify
                </v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-simple-table v-if="itemToShow">
        <thead>
          <tr>
            <th class="primary--text">
              ID
            </th>
            <th class="primary--text">
              Marque
            </th>
            <th class="primary--text">
              Modèle
            </th>
            <th class="primary--text">
              Dimension
            </th>
            <th class="primary--text">
              Prix
            </th>
            <th class="primary--text">
              Stock
            </th>
            <th />
          </tr>
        </thead>

        <tbody v-if="recherche===false">
          <tr
            v-for="item in items"
            :key="item.id"
          >
            <td> {{ item.id }} </td>
            <td> {{ item.marque }} </td>
            <td> {{ item.modele }} </td>
            <td> {{ item.diametre }} </td>
            <td> {{ item.public_price }} </td>
            <td> {{ item.stock }} </td>
            <td>
              <v-btn
                @click="ModifierJante(item)"
              >
                <v-icon>
                  mdi-pencil
                </v-icon>
              </v-btn>
              <v-btn
                color="error"
                @click="SupprimerJante(item)"
              >
                <v-icon>
                  mdi-close
                </v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr
            v-for="element in test"
            :key="element.id"
          >
            <td>
              <v-btn
                color="blue"
                @click="GoDetailsCommande(item)"
              >
                <v-icon left>
                  mdi-pencil
                </v-icon>
                Editer
              </v-btn>
            </td>
            <td> {{ element.id }} </td>
            <td> {{ element.reference }} </td>
            <td> {{ element.marque }} </td>
            <td> {{ element.modele }} </td>
            <td> {{ element.diametre }} </td>
            <td> {{ element.public_price }} </td>
            <td> {{ element.stock }} </td>
          </tr>
        </tbody>
      </v-simple-table>
    </base-material-card>

    <div class="py-3" />
  </v-container>
</template>

<script>
  // Utilities
  import axios from 'axios'
  import VueAxios from 'vue-axios'
  import Vue from 'vue'
  import {
    mapState,
  } from 'vuex'

  Vue.use(VueAxios, axios)

  export default {
    name: 'DashboardCoreDrawer',

    props: {
      expandOnHover: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      items: [],
      itemToShow: true,
      rechercheNom: null,
      recherche: false,
      test: [],
    }),
    computed: {
      ...mapState(['barColor', 'barImage']),
      drawer: {
        get () {
          return this.$store.state.drawer
        },
        set (val) {
          this.$store.commit('SET_DRAWER', val)
        },
      },
      computedItems () {
        return this.items.map(this.mapItem)
      },
      profile () {
        return {
          avatar: true,
          title: this.$t('site_title'),
        }
      },
    },
    created () {
      // Récupération des marques de véhicule pour le configurateur
      Vue.axios.get('https://api.espace-jantes.com/allJantesAdmin')
        .then((response) => {
          if (response.data.length === 0) {
            this.itemToShow = false
            return this.itemToShow
          }
          response.data.forEach(element => {
            this.items.push(element)
          },
          )
        })
        .catch((error) => {
          console.log(error)
          this.itemToShow = false
        })
    },
    methods: {
      mapItem (item) {
        return {
          ...item,
          children: item.children ? item.children.map(this.mapItem) : undefined,
          title: this.$t(item.title),
        }
      },
      GoDetailsJantes: function (item) {
        this.$router.push({ name: 'JantesDetails', params: { infoJantes: item } })
      },
      ajouterJante () {
        this.$router.push({ name: 'Ajout jantes' })
      },
      synchronisationAlgolia () {
        // Vue.axios.get('https://api.espace-jantes.com/synchroJantesAlgolia')
        // .then((response) => {
        //   console.log('SYNCHRONISATION')
        // })
        // .catch((error) => {
        //   console.log(error)
        // })
      },
      rechercherNom: function (rechercheNom) {
        if (this.recherche === false) {
          this.recherche = true
          this.items.forEach(item => {
            if (item.marque === rechercheNom || item.modele === rechercheNom) {
              this.test.push(item)
            }
          })
        } else {
          this.test = []
          this.items.forEach(item => {
            if (item.marque === rechercheNom || item.modele === rechercheNom) {
              this.test.push(item)
            }
          })
        }
      },
      supprimerNom: function (rechercheNom) {
        this.recherche = false
        this.test = []
      },
      ModifierJante: function (jante) {
        this.$router.push({ name: 'JantesDetails', params: { infoJantes: jante } })
      },
      SupprimerJante: function (jante) {
        Vue.axios.put('https://api.espace-jantes.com/supprimerJante', {
          params: { infoJante: jante },
        })
          .then((response) => {
            var self = this
            setTimeout(function () { self.$router.go(0) }, 2000)
          })
          .catch(error => console.log(error))
      },
      trierMarque: function () {
        console.log('yrdy')
      },
    },
  }
</script>
<style scoped>
  input[type=text] {
    border: 2px solid black !important;
    border-radius : 4px;
    margin-right : 20px;
  }
</style>
